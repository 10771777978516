body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* Custom classes */

.background{
  color: #595858;
}

.menu-button{
  margin-left: -12;
  margin-right: 20;
}

.title{
  display: 'none',
}

.grow{
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.search{
  position: relative;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.15);
  margin-right: 1em;
}

.search:hover{
  background-color: rgba(255,255,255,0.25);
}

.search-icon{
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
 
.input-input{
  padding: 0rem 0rem 0rem 2rem;
  -webkit-transition: width 1s linear;
  transition: width 1s linear;
  width: 100%;
  color: #fff !important;
}

.main-search img{
  margin: 2rem auto;
  -webkit-transition: 150ms width linear;
  transition: 150ms width linear;
}

.input-flex{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.input-flex button{
  margin-left: 1rem;
}

.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #355C57;
  color: white;
  padding: 0.5rem;
  box-sizing: border-box;
}

.footer small{
  font-size: 0.5rem;
}

.text-field{
  width: 40%;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 150px white inset;
}

.text-field:focus{
  color: white;
}

.results-quantity{
  background-color: #5252c4;
  width: 90px;
  font-size: 11px;
  margin: 10px auto;
  color: white;
  padding: 0.25rem;
}

.filters-results{
  display: -webkit-flex;
  display: flex;
}

.toggle-filters{
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 18px;
  position: relative;
}

.toggle-filters:after{
  content: '';
  width: 100%;
  border-bottom: dashed 1px rgb(143, 143, 143);
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

.toggle-filters p{
  cursor: pointer;
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  font-weight: lighter;
  margin: 14px;
  background-color: white;
}

.transition-test{
  -webkit-transition: 200ms display linear;
  transition: 200ms display linear;
}

.filtros{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.filtros > div {
  width: auto;
  margin: 0 1rem;
}

.panel-responsive{
  width:80%;
  background-color: #f7f7f7; 
  margin: 3px auto !important;
}

.pagination{
  padding: 0px 0 0px 0px;
}

.pagination > div{
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

label{
  background-color: white;
  padding: 0 5px 0 0 !important;
  box-sizing: border-box;
}


@media(max-width: 370px){
  .panel-responsive{
    width:60%;
  } 
  
  .footer{
    font-size: 0.5rem;
  }
}

@media(max-width: 375px){
 body{
   font-size: 12px;
 }
}

@media(max-width: 490px){
  .panel-responsive{
    width:70%;
  } 
  
  .input-flex{
   -webkit-flex-direction: column;
           flex-direction: column;
  }
  
  .input-flex > div{
    margin: 10px 0;
  }
}

@media(max-width: 600px){
  .title{
    display: 'block',
  } 

  .search{
    margin-left: 1rem;
    width: auto;
  }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

